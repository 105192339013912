
@import '~@angular/material/theming';
@include mat-core();

// Options for colors:
// https://trevier.medium.com/angular-material-theme-color-options-7d5968cb7460
// https://www.materialpalette.com/

.theme-app18 {
	margin-top: 15px;
	/* # Global Font Size Section
	================================================
	================================================*/
	$h1-font-size: 24px;
	$h2-font-size: 24px;
	$h3-font-size: 22px;
	$h4-font-size: 17px;
	$h5-font-size: 15px;
	$h6-font-size: 14px;
	$text-font-size: 14px;

	/* # Settings Size Avatar Section
	================================================
	================================================*/
	$item-md-avatar-size: 72px;
	$item-ios-avatar-size: 72px;

	/* # Settings Size Thumbnail Section
	================================================
	================================================*/
	$item-md-thumbnail-size: 120px;
	$item-ios-thumbnail-size: 120px;

	.dark {
		--primary : #000000;
		--secondary: #C7C7CC;
		--secondaryBase: #fff;
		--accent : #ffe734;
		--secondaryDark: #383D43;
		--accentLight :#ffe734;
		--accentDark: #ffe734;
		--borderColor: #ffe734;
		--accentHover: rgba(108, 212, 202, 0.5);

		--primaryText: #fff;
		--secondaryText: #8E8E93; 
		--accentText : #ffe734;
		--secondaryBaseText: #EFEFF4;
		--accentLightText: #2D3035;
		--accentDarkText: #cecece;

		/** primary **/
		--ion-color-primary: #2D3035;
		--ion-color-primary-rgb: 45, 48, 53;
		--ion-color-primary-contrast: #ffffff;
		--ion-color-primary-contrast-rgb: 255,255,255;
		--ion-color-primary-shade: #3171e0;
		--ion-color-primary-tint: #4c8dff;

		/** secondary **/
		--ion-color-secondary: var(--accent);
		--ion-color-secondary-rgb: 199, 199, 204;
		--ion-color-secondary-contrast: #ffffff;
		--ion-color-secondary-contrast-rgb: 255,255,255;
		--ion-color-secondary-shade: #0bb8cc;
		--ion-color-secondary-tint: #24d6ea;

		/** tertiary **/
		--ion-color-tertiary: #7044ff;
		--ion-color-tertiary-rgb: 112,68,255;
		--ion-color-tertiary-contrast: #ffffff;
		--ion-color-tertiary-contrast-rgb: 255,255,255;
		--ion-color-tertiary-shade: #633ce0;
		--ion-color-tertiary-tint: #7e57ff;

		/** success **/
		--ion-color-success: #10dc60;
		--ion-color-success-rgb: 16,220,96;
		--ion-color-success-contrast: #ffffff;
		--ion-color-success-contrast-rgb: 255,255,255;
		--ion-color-success-shade: #0ec254;
		--ion-color-success-tint: #28e070;

		/** warning **/
		--ion-color-warning: #ffce00;
		--ion-color-warning-rgb: 255,206,0;
		--ion-color-warning-contrast: #ffffff;
		--ion-color-warning-contrast-rgb: 255,255,255;
		--ion-color-warning-shade: #e0b500;
		--ion-color-warning-tint: #ffd31a;

		/** danger **/
		--ion-color-danger: #f04141;
		--ion-color-danger-rgb: 245,61,61;
		--ion-color-danger-contrast: #ffffff;
		--ion-color-danger-contrast-rgb: 255,255,255;
		--ion-color-danger-shade: #d33939;
		--ion-color-danger-tint: #f25454;

		/** dark **/
		--ion-color-dark: #222428;
		--ion-color-dark-rgb: 34,34,34;
		--ion-color-dark-contrast: #ffffff;
		--ion-color-dark-contrast-rgb: 255,255,255;
		--ion-color-dark-shade: #1e2023;
		--ion-color-dark-tint: #383a3e;

		/** medium **/
		--ion-color-medium: #989aa2;
		--ion-color-medium-rgb: 152,154,162;
		--ion-color-medium-contrast: #ffffff;
		--ion-color-medium-contrast-rgb: 255,255,255;
		--ion-color-medium-shade: #86888f;
		--ion-color-medium-tint: #a2a4ab;

		/** light **/
		--ion-color-light: #f4f5f8;
		--ion-color-light-rgb: 244,244,244;
		--ion-color-light-contrast: #0a0a0a;
		--ion-color-light-contrast-rgb: 0,0,0;
		--ion-color-light-shade: #d7d8da;
		--ion-color-light-tint: #f5f6f9;
		
		--ion-background-color: var(--primary);
		--ion-border-color: var(--borderColor);

		$dark-primary: mat-palette($mat-yellow);
		$dark-accent:  mat-palette($mat-yellow, A200, A100, A400);
		$dark-warn:    mat-palette($mat-deep-orange);
		$dark-theme:   mat-dark-theme($dark-primary, $dark-accent, $dark-warn);
		@include angular-material-theme($dark-theme);
		
	}

	.light {
		--primary : rgb(253, 253, 253);
		--secondary: #C7C7CC;
		--secondaryBase: #fff;
		--accent : #F44336;
		--secondaryDark: rgb(42, 53, 68);
		--accentLight : #f98981;
		--accentDark: rgb(106, 43, 39);
		--borderColor: rgba(0, 0, 0, .5);

		--primaryText: rgb(29, 29, 29);
		--secondaryText: #8E8E93;
		--accentText : #ef233c;
		--secondaryBaseText: #EFEFF4;
		--accentLightText: #2D3035;
		--accentDarkText: #00000;

		/** primary **/
		--ion-color-primary: #2D3035;
		--ion-color-primary-rgb: 45, 48, 53;
		--ion-color-primary-contrast: #ffffff;
		--ion-color-primary-contrast-rgb: 255,255,255;
		--ion-color-primary-shade: #3171e0;
		--ion-color-primary-tint: #4c8dff;

		/** secondary **/
		--ion-color-secondary:#C7C7CC;
		--ion-color-secondary-rgb: 199, 199, 204;
		--ion-color-secondary-contrast: #ffffff;
		--ion-color-secondary-contrast-rgb: 255,255,255;
		--ion-color-secondary-shade: #0bb8cc;
		--ion-color-secondary-tint: #24d6ea;

		/** tertiary **/
		--ion-color-tertiary: #7044ff;
		--ion-color-tertiary-rgb: 112,68,255;
		--ion-color-tertiary-contrast: #ffffff;
		--ion-color-tertiary-contrast-rgb: 255,255,255;
		--ion-color-tertiary-shade: #633ce0;
		--ion-color-tertiary-tint: #7e57ff;

		/** success **/
		--ion-color-success: #10dc60;
		--ion-color-success-rgb: 16,220,96;
		--ion-color-success-contrast: #ffffff;
		--ion-color-success-contrast-rgb: 255,255,255;
		--ion-color-success-shade: #0ec254;
		--ion-color-success-tint: #28e070;

		/** warning **/
		--ion-color-warning: #ffce00;
		--ion-color-warning-rgb: 255,206,0;
		--ion-color-warning-contrast: #ffffff;
		--ion-color-warning-contrast-rgb: 255,255,255;
		--ion-color-warning-shade: #e0b500;
		--ion-color-warning-tint: #ffd31a;

		/** danger **/
		--ion-color-danger: #f04141;
		--ion-color-danger-rgb: 245,61,61;
		--ion-color-danger-contrast: #ffffff;
		--ion-color-danger-contrast-rgb: 255,255,255;
		--ion-color-danger-shade: #d33939;
		--ion-color-danger-tint: #f25454;

		/** dark **/
		--ion-color-dark: #222428;
		--ion-color-dark-rgb: 34,34,34;
		--ion-color-dark-contrast: #ffffff;
		--ion-color-dark-contrast-rgb: 255,255,255;
		--ion-color-dark-shade: #1e2023;
		--ion-color-dark-tint: #383a3e;

		/** medium **/
		--ion-color-medium: #989aa2;
		--ion-color-medium-rgb: 152,154,162;
		--ion-color-medium-contrast: #ffffff;
		--ion-color-medium-contrast-rgb: 255,255,255;
		--ion-color-medium-shade: #86888f;
		--ion-color-medium-tint: #a2a4ab;

		/** light **/
		--ion-color-light: #f4f5f8;
		--ion-color-light-rgb: 244,244,244;
		--ion-color-light-contrast: #000000;
		--ion-color-light-contrast-rgb: 0,0,0;
		--ion-color-light-shade: #d7d8da;
		--ion-color-light-tint: #f5f6f9;
		--ion-background-color: var(--primary);

		// Light theme
		$light-primary: mat-palette($mat-red, 200, 500, 300);
		$light-accent: mat-palette($mat-red, 200);
		$light-warn: mat-palette($mat-deep-orange, 200);
		$light-theme: mat-light-theme($light-primary, $light-accent, $light-warn);
		@include angular-material-theme($light-theme)
	}

	@import "./common.scss";
}