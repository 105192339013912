// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

// https://fonts.google.com/specimen/Roboto
// https://www.cloudhadoop.com/angular-google-font-library/
// https://forum.ionicframework.com/t/ionic-5-changing-default-font-family/187141/2
@font-face {
	font-family: Roboto-BlackItalic;
	src: url("../assets/fonts/Roboto-BlackItalic.ttf");
}
@font-face {
	font-family: Roboto-Black;
	src: url("../assets/fonts/Roboto-Black.ttf");
}
@font-face {
	font-family: Roboto-BoldItalic;
	src: url("../assets/fonts/Roboto-BoldItalic.ttf");
}
@font-face {
	font-family: Roboto-Bold;
	src: url("../assets/fonts/Roboto-Bold.ttf");
}
@font-face {
	font-family: Roboto-Italic;
	src: url("../assets/fonts/Roboto-Italic.ttf");
}
@font-face {
	font-family: Roboto-LightItalic;
	src: url("../assets/fonts/Roboto-LightItalic.ttf");
}
@font-face {
	font-family: Roboto-Light;
	src: url("../assets/fonts/Roboto-Light.ttf");
}
@font-face {
	font-family: Roboto-MediumItalic;
	src: url("../assets/fonts/Roboto-MediumItalic.ttf");
}
@font-face {
	font-family: Roboto-Medium;
	src: url("../assets/fonts/Roboto-Medium.ttf");
}
@font-face {
	font-family: Roboto-Regular;
	src: url("../assets/fonts/Roboto-Regular.ttf");
}
@font-face {
	font-family: Roboto-ThinItalic;
	src: url("../assets/fonts/Roboto-ThinItalic.ttf");
}
@font-face {
	font-family: Roboto-Thin;
	src: url("../assets/fonts/Roboto-Thin.ttf");
}

:root {
	--ion-font-family: "Roboto-Regular" , "Roboto-Light", sans-serif !important;
	/** primary **/
	--ion-color-primary: #3880ff;
	--ion-color-primary-rgb: 56, 128, 255;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255, 255, 255;
	--ion-color-primary-shade: #3171e0;
	--ion-color-primary-tint: #4c8dff;

	/** secondary **/
	--ion-color-secondary: #3dc2ff;
	--ion-color-secondary-rgb: 61, 194, 255;
	--ion-color-secondary-contrast: #ffffff;
	--ion-color-secondary-contrast-rgb: 255, 255, 255;
	--ion-color-secondary-shade: #36abe0;
	--ion-color-secondary-tint: #50c8ff;

	/** tertiary **/
	--ion-color-tertiary: #5260ff;
	--ion-color-tertiary-rgb: 82, 96, 255;
	--ion-color-tertiary-contrast: #ffffff;
	--ion-color-tertiary-contrast-rgb: 255, 255, 255;
	--ion-color-tertiary-shade: #4854e0;
	--ion-color-tertiary-tint: #6370ff;

	/** success **/
	--ion-color-success: #2dd36f;
	--ion-color-success-rgb: 45, 211, 111;
	--ion-color-success-contrast: #ffffff;
	--ion-color-success-contrast-rgb: 255, 255, 255;
	--ion-color-success-shade: #28ba62;
	--ion-color-success-tint: #42d77d;

	/** warning **/
	--ion-color-warning: #ffc409;
	--ion-color-warning-rgb: 255, 196, 9;
	--ion-color-warning-contrast: #000000;
	--ion-color-warning-contrast-rgb: 0, 0, 0;
	--ion-color-warning-shade: #e0ac08;
	--ion-color-warning-tint: #ffca22;

	/** danger **/
	--ion-color-danger: #eb445a;
	--ion-color-danger-rgb: 235, 68, 90;
	--ion-color-danger-contrast: #ffffff;
	--ion-color-danger-contrast-rgb: 255, 255, 255;
	--ion-color-danger-shade: #cf3c4f;
	--ion-color-danger-tint: #ed576b;

	/** dark **/
	--ion-color-dark: #222428;
	--ion-color-dark-rgb: 34, 36, 40;
	--ion-color-dark-contrast: #ffffff;
	--ion-color-dark-contrast-rgb: 255, 255, 255;
	--ion-color-dark-shade: #1e2023;
	--ion-color-dark-tint: #383a3e;

	/** medium **/
	--ion-color-medium: #92949c;
	--ion-color-medium-rgb: 146, 148, 156;
	--ion-color-medium-contrast: #ffffff;
	--ion-color-medium-contrast-rgb: 255, 255, 255;
	--ion-color-medium-shade: #808289;
	--ion-color-medium-tint: #9d9fa6;

	/** light **/
	--ion-color-light: #f4f5f8;
	--ion-color-light-rgb: 244, 245, 248;
	--ion-color-light-contrast: #000000;
	--ion-color-light-contrast-rgb: 0, 0, 0;
	--ion-color-light-shade: #d7d8da;
	--ion-color-light-tint: #f5f6f9;
	@import "./theme-app2.scss";
	@import "./theme-app4.scss";
	@import "./theme-app6.scss";
	@import "./theme-app8.scss";
	@import "./theme-app10.scss";
	@import "./theme-app12.scss";
	@import "./theme-app14.scss";
	@import "./theme-app16.scss";
	@import "./theme-app18.scss";
	@import "./theme-app20.scss";



}
