@import '~@angular/material/theming';
@include mat-core();

.theme-app4{

	.dark {
		$dark-primary: mat-palette($mat-light-blue, A200, A100, A400);
		$dark-accent:  mat-palette($mat-light-blue, A200, A100, A400);
		$dark-warn:    mat-palette($mat-deep-orange);
		$dark-theme:   mat-dark-theme($dark-primary, $dark-accent, $dark-warn);

		@include angular-material-theme($dark-theme);
		--primary : #2D3035;
		--secondary: #C7C7CC;
		--secondaryBase: #fff;
		--accent : #689fff;
		--secondaryDark: #383D43;
		--accentLight :rgba(138, 209, 202, .3);
		--accentBackground :rgba(138, 209, 202, .1);
		--accentDark: #376c65;
		--borderColor: #689fff;
		--accentHover: rgba(108, 212, 202, 0.5);
		--iconColor: #5aa1bf;

		--primaryText: #fff;
		--secondaryText: #8E8E93; 
		--accentText : #6CD4CA;
		--secondaryBaseText: #EFEFF4;
		--accentLightText: #2D3035;
		--accentDarkText: #000000;

		--ion-background-color: var(--primary);
	}

	.light {
		$light-primary: mat-palette($mat-light-blue, 200, 500, 300);
		$light-accent: mat-palette($mat-light-blue, 200);
		$light-warn: mat-palette($mat-deep-orange, 200);
		$light-theme: mat-light-theme($light-primary, $light-accent, $light-warn);
		@include angular-material-theme($light-theme);
		--primary : rgb(253, 253, 253);
		--secondary: #C7C7CC;
		--secondaryBase: #fff;
		--accent : #00509f;
		--secondaryDark: rgb(42, 53, 68);
		--accentLight : rgba(255, 255, 255, 0.15);
		--accentBackground :rgba(255, 255, 255, 0.05);
		--accentDark: rgb(106, 43, 39);
		--borderColor: rgba(233,236,239, 0.8);
		--accentHover: rgba(255,70,64, 0.1);
		--iconColor: #5aa1bf;

		--primaryText: #73777c;
		--secondaryText: #8E8E93;
		--accentText : #00509f;
		--secondaryBaseText: #EFEFF4;
		--accentLightText: #2D3035;
		--accentDarkText: #00000;

		--ion-background-color: var(--primary);
	}

	@import "./common.scss";
}