
$h1-font-size: 24px;
$h2-font-size: 24px;
$h3-font-size: 22px;
$h4-font-size: 17px;
$h5-font-size: 15px;
$h6-font-size: 14px;
$text-font-size: 14px;
$item-md-avatar-size: 72px;
$item-ios-avatar-size: 72px;
$item-md-thumbnail-size: 120px;
$item-ios-thumbnail-size: 120px;

body {
	-webkit-user-select: text;
	-moz-user-select: text;
	-ms-user-select: text;
	user-select: text;
}
ion-label {
	-webkit-user-select: text;
	-moz-user-select: text;
	-ms-user-select: text;
	user-select: text;
}

ion-item{
	background-color: var(--ion-background-color);
	border-bottom: 1px solid var(--secondary);
}

.file-button{
	margin: 20px;
	background: transparent !important;
	color: var(--primaryText);
	font-size: $h4-font-size !important;
	font-weight: 300 !important;
	height: 44px !important;
}

.file-button::-webkit-file-upload-button {
	background: transparent !important;
	margin-right: 10px !important;
	color: var(--accent);
	border-width: 2px;
	border-style: solid;
	border-color: var(--accent) !important;
	font-size: $h4-font-size !important;
	font-weight: 300 !important;
	height: 44px !important;
	box-shadow: none !important;
	border-radius: 8px !important;
}

// Settings Style distance icon text
main-menu .item-inner {
	margin-left: 6px !important;
}

ion-split-pane {
	--side-max-width: 280px;
	--side-min-width: 280px;
}

#leftMenu {
	border-color: var(--borderColor) !important;
	background-color: var(--primary);
	
	ion-content {
		// overflow-y:hidden !important;
		// overflow-y: scroll;
		// padding-right: 10px; /* Increase/decrease this value for cross-browser compatibility */
		// padding-top: 150px !important;
		// box-sizing: content-box; /* So the width will be 100% + 17px */
	}
}

.dark {
	.menuLogo{
		margin-top: 15px;
		margin-bottom: 15px;
		background-image: url("../assets/app/logo-dark.png"), url("../assets/app/logo.png");
		height: 40px;
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;
		padding: 10px;
	}
	.menuLogoIos{
		margin-top: 55px; // this is required for the iOS notch onthe phone
		margin-bottom: 15px;
		background-image: url("../assets/app/logo-dark.png"), url("../assets/app/logo.png");
		height: 40px;
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;
		padding: 10px;
	}
}
.light {
	.menuLogo{
		margin-top: 15px;
		margin-bottom: 15px;
		background-image: url("../assets/app/logo-light.png"), url("../assets/app/logo.png");
		height: 40px;
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;
		padding: 10px;
	}
	.menuLogoIos{
		margin-top: 45px; // this is required for the iOS notch onthe phone
		margin-bottom: 15px;
		background-image: url("../assets/app/logo-light.png"), url("../assets/app/logo.png");
		height: 40px;
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;
		padding: 10px;
	}
}


.menuIcon {
	color: var(--iconColor) !important;
}

.padded-divider  {
	padding: 3px;
	margin-bottom: 10px;
	width: 100%;
	border-bottom: 3px solid  var(--accent) !important;
}

ion-radio {
	--color-checked: var(--accent);
	--color: var(--accent);
	width: 20px;
	height: 20px;
}

ion-button {
	--background: var(--accentBackground);
	color: var(--accent);
	--border-width: 2px;
	--border-style: solid;
	border-color: var(--accent) !important;
	font-size: $h4-font-size !important;
	--font-weight: 300 !important;
	--height: 44px !important;
	--box-shadow: none !important;
	--border-radius: 8px !important;
	--background-hover: var(--accentLight);
}

.button-style {
	--background: var(--accentBackground);
	color: var(--accent);
	--border-width: 2px;
	--border-style: solid;
	border-color: var(--accent) !important;
	font-size: $h4-font-size !important;
	--font-weight: 300 !important;
	--height: 44px !important;
	--box-shadow: none !important;
	--border-radius: 8px !important;
	--background-hover: var(--accentLight);
}

.button-style:hover {
	cursor:pointer;
}

.button-cancel {
	color: red;
	border: none !important;
	--border-width: 0px !important;
}

.small-button {
	height: 20px;
	font-size: 10px !important;
	margin: 3px;
	color: var(--accent);
}

.medium-button {
	height: 25px;
	font-size: 15px !important;
	margin-top: 10px;
	margin-bottom: 10px;
	color: var(--accent);
}

.default-h1 {
	font-size: $h1-font-size;
	color: var(--primaryText);
	font-weight: 300;
}

.default-h2 {
	font-size: $h2-font-size;
	color: var(--primaryText);
	font-weight: 300;
}

.default-h3 {
	font-size: $h3-font-size;
	color: var(--primaryText);
	font-weight: 400;
}

.default-h4 {
	font-size: $h4-font-size;
	color: var(--primaryText);
	font-weight: 300;
}

.default-h5 {
	font-size: $h5-font-size;
	color: var(--primaryText);
	font-weight: 200;
}

.default-h6 {
	font-size: $h6-font-size;
	color: var(--primaryText);
	font-weight: 100;
}

.default-p {
	font-size: $text-font-size;
	color: var(--primaryText);
	font-weight: 300;
}

.default-small {
	font-size: 12px;
	color: var(--primaryText);
	font-weight: 1;
}

// Settings Icon Header
ion-header {
	[class^="icon-"]:before, [class*=" icon-"]:before {
		width: auto !important;
		margin-left: 0 !important;
	}
	background-color: var(--primary);
}

ion-footer {
	background-color: var(--primary);
}

.header-md::after {
	background-image: none !important;
}

ion-toolbar {
	color: var(--accent);
}

.toolbar {
	.toolbar-title {
		font-weight: 400;
	}
	background-color: var(--primary);
	--background: var(--primary);
	ion-title {
		font-size: larger;
	}
	ion-title, ion-title-ios, ion-back-button, ion-butto, ion-icon, ion-menu-button  {
		color: var(--accentText);
	}
}

.toolbar-icon {
	color: var(--accentText);
}

.alert-controller-withTheme{
	overflow:hidden;
	--background:var(--primary);
	color:var(--primaryText) !important;

	.alert-wrapper {
		border-radius: 15px !important;
		background: var(--primary);
		border-color: var(--accent) !important;
	}
	.alert-head {
		.alert-title{
			color: var(--primaryText);
		}
	}
	.alert-message {
		color: var(--primaryText);
		strong {
			color: var(--ion-color-danger);
		}
	}
	.alert-button-group {
		#cancel-button.alert-button{
			.alert-button-inner{
				color: var(--secondaryText);
			}
		}
		#confirm-button.alert-button{
			.alert-button-inner{
				font-weight: bold;
				color: var(--ion-color-danger);
			}
		}
		#confirm-button-good.alert-button{
			.alert-button-inner{
				font-weight: bold;
				color: var(--primaryText);
			}
		}
	}
}

// ion-input{
// 	border-radius: 6px !important;
// 	margin-top:5px;
// 	color: var(--primaryText);
// 	--background: rgba(219,219,219, .03) !important;
	
// }

ion-input{
	border-radius: 10px !important;
	margin-top: 5px;
	margin-bottom: 5px;
	margin-left: 10px;
	margin-right: 10px;
	font-size: 0.9em;
	color: var(--primaryText);
	--background: rgba(219,219,219, .03) !important;
	/* height: 10%; */
	/* width: 41%; */
}

.withTheme {
	border: 2px solid var(--borderColor);
	margin: 10px;
	padding: 0px !important;
	border-radius: 15px;
	overflow:hidden;
	color:var(--primaryText) !important;

	ion-card-title, ion-card-content, ion-label, mat-list-item{
		color: var(--primaryText) !important;
	}

	span {
		color:var(--primaryText) !important;	
	}

	ion-img {
		--border-radius: 8px;
	}

	ion-thumbnail {
		--border-radius: 8px;
	}

	ion-item:hover {
		border-left: 8px solid var(--borderColor);
		transition: border-width 0.1s ease-in-out;
	}

	ion-item.nohover:hover {
		border-left: none;
	}

	ion-card-subtitle {
		color: var(--secondaryText) !important;
	}

	div.small-distance {
		margin: 2px;
		color: var(--primaryText);
		border: 1px solid var(--borderColor);
		font-size: 10px;
		padding-top:3px;
		padding-left: 5px;
		padding-right: 5px;
		border-radius: 7px;
		height: 20px;
	}

	.aligned-with-icon{
		position: relative;
		font-size: 8px;
		top: 11px;
		margin-left: -25px; /* optional */
		margin-right: 8px;
	}
	
}

.thumbnail-rounded {
	border-radius: 6px!important;
}

// .withTheme:hover {
// 	-webkit-box-shadow: 0px 0px 20px -4px var(--accentHover); 
// 	box-shadow: 0px 0px 20px -4px var(--accentHover);
// }

.leftMenuColor ion-icon {
	color: var(--accent);
	font-family: Montserrat,sans-serif;
}

.leftMenuColor ion-label {
	color: var(--primaryText);
	font-family: Montserrat,sans-serif;
	font-weight: thin !important;
}

.leftMenuColor ion-toggle {
	--background: #3c3e42;
	--background-checked: #3c3e42;
	
	--handle-background: var(--accent);
	--handle-background-checked: var(--accent);
}

.floatInput {
	ion-label{
		color:var(--primaryText);
	}
	ion-input{
		color:var(--primaryText);
	}
}

.breakline-space {
	margin:10px; 
	border:1px solid var(--borderColor) !important;
}

ion-badge {
	border-radius: 10px !important;
	padding: 10px 10px !important;
	margin: 10px;
	border:1px solid var(--borderColor) !important;
}
ion-badge.failure {
	border:1px solid red !important;
}
ion-badge.success {
	border:1px solid white !important;
}

.row-is-selected{
	background-color: rgba($color: lightgray, $alpha: 0.8);
}

.alertClass {
	.alert-title{
		font-weight: bolder;
	}
	color: white;
	background-color:rgba(200, 200, 200, 0.2);
}

.alertClassActionButton{
	border: white;
	border: 2px solid var(--borderColor);
	border-radius: 7px;
}

.alert-sub-title.sc-ion-alert-md, .alert-title.sc-ion-alert-md, .alert-message, .sc-ion-alert-md {
	color: rgb(224, 224, 224);
}

.toastError{
	--background: red;
	--color: black; 
}

// https://ionicframework.com/docs/api/select-option
// https://stackoverflow.com/questions/69190742/change-the-color-of-selected-item-ion-select
.custom-options {
	--placeholder-opacity: 1;
	ion-select::part(text) {
		color: var(--primaryText);
	}
	color: var(--primaryText);
	--placeholder-color: var(--primaryText);
}
.custom-options .select-interface-option {
	--color: var(--primaryText);
	color: var(--primaryText);
}

.primaryText {
	color: var(--primaryText)
}

.selectClass {
	ion-select{
		max-width: 70% !important;
	}

	min-width: 70%;
	color: white  !important;
	.sc-ion-alert-md{
		color: white;
	}

	.alert-radio-inner.sc-ion-alert-md {
		background-color: white !important;
	}
	
		// wrap the text
	.alert-radio-label.sc-ion-alert-md {
		color: white;
		font-weight: bold;
	}
	.alert-button.alert-button-inner {
		color: white;
	}
	.popover-content {
		min-width: 90% !important;
	}
}

.dateRanger{
	color: var(--ion-color-danger);
	font-weight: bold;
	font-size: large;
}

.eventFloater{
	color: white;
	font-weight: bold;
	padding: 10px;
	border-radius: 10px;
	font-size: large;
	background-color: var(--ion-color-danger);
}


.chatContainer {
	// border: 2px solid var(--borderColor);
	// border-radius: 15px;
	ion-card-subtitle {
		padding-top: 3px;
	}
}

.chatContainer::after {
	content: "";
	clear: both;
	display: table;
	}

.chatMessageOtherUser{
	color: white; float: left;
}

.chatMessageLoggedInUser{
	color: white; float: right;
}

.chatUsername{
	font-weight: bolder;
}

.ion-icon{
	color: var(--accentText);
}

.numberCard {
	ion-card-header {
		color:white !important;
		ion-card-subtitle{
			color:white !important;
			font-weight: bold;
			font-size: 20px !important;

			ion-icon{
				font-size: 30px !important;
				color: var(--borderColor);
			}
		}
		ion-card-title{
			color: var(--primary) !important;
			font-weight: bold;
			padding: 10px;
			background: var(--accent);
			border-radius: 10px;
			float: right;
		}
	}
	ion-card-content {
		ion-button {
			margin-top: 10px;
		}
		div.leftBound {
			margin-top:10px;
			padding: 5px;
			border-left: 2px solid var(--borderColor);
			border-bottom: 2px solid var(--borderColor);
			border-bottom-left-radius: 10px;

		}
		div.leftBound:hover {
			border-left: 8px solid var(--borderColor);
			transition: border-width 0.1s ease-in-out;
		}
	}
}

.qrCodeStyle{
	img {
		border-radius: 25px;
	}
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: 1;
}


.matt-tree-styling {
	background-color: transparent;
	padding-top: 20px;
	margin-top: 20px;

	.tree-nested-node {
		padding-left: 30px;
		margin-left: 10px;
		padding-left: 40px; 
	}
	
	mat-tree {
		margin-left: 40px !important;
		background-color: transparent;
	}
	
	.mat-tree-hover:hover {
		background-color: var(--accentHover) !important;
		border-radius: 5px;
	}
	
	.mat-tree-node {
		padding: 3px;
		background-color: transparent;

	}
	
	.mat-nested-tree-node {
		top: -24px !important;
		background-color: transparent;

	}
	
	ul, li { 
		list-style: none; 
		margin: 0; 
		padding: 0;  
		background-color: transparent;

	}
	
	li.tree-container {
		border-bottom: 0;
		background-color: transparent;

	}
	
	li { 
		padding-left: 40px;
		border-left: 1px dotted grey !important;
		border-bottom: 1px dotted grey !important;
		border-bottom-left-radius:10px;
		border-width: 0 0 1px 1px; 
		position: relative;
		top: -24px;
		background-color: transparent;
	}
		
	li.mat-tree-node, li div { 
		margin: 0;
		position: relative;
		top: 15px !important; 
		background-color: transparent;

	}
		
	li ul {
		padding-right: 80px !important;
		background-color: transparent;
	}
}

.popover_style {
	ion-backdrop{
		--backdrop-opacity: 0.9;
	}
}

.full-modal-cover {
	--border-radius: 15px;
	--min-height: 85% !important;
	--min-width: 85% !important;
	--padding:10px !important;
	.modal-wrapper{
		height: 30%;
		width: 90%;
		border-radius: 10px;
	}
}

.full-modal-cover-narrow {
	--border-radius: 15px;
	--min-height: 85% !important;
	--min-width: 450px !important;
	--padding:10px !important;
	.modal-wrapper{
		height: 30%;
		width: 90%;
		border-radius: 10px;
	}
}

.small-modal-cover .modal-wrapper {
	border-radius: 15px;
	padding: 10px;
}


//increase size of modal
.no-background-modal .modal-wrapper{
	--min-height: 95% !important;
	--min-width: 75% !important;
	--background: rgba(46, 46, 46, 0);
	--border-style: none;
	--box-shadow:none;
	--border-radius:none;
	--border-style:none;
	--border-color:none;
	--border-width:0px;
}

.small-no-background-modal .modal-wrapper{
	--background: rgba(46, 46, 46, 0) !important;
	--border-style: none !important;
	--box-shadow:none !important;
	--border-radius:none !important;
	--border-style:none !important;
	--border-color:none !important;
	--border-width:0px !important;
}

.firmware-chain {
	font-size: $h4-font-size !important;
	--font-weight: 300 !important;
	--height: 44px !important;
	--box-shadow: none !important;
	--border-radius: 8px !important;
	--background-hover: var(--accentLight);
}

.fab-close {
	font-size: large !important;
	font-weight: bold !important;
	border-color: var(--accent) !important;
	border-radius: 18px !important;
}
.fab-close:hover{
	background-color: var(--accent) !important;
}

// Scroll Bar
// TODO :: Handle this more dynamically with the shadow dom
// https://www.npmjs.com/package/ion-custom-scrollbar
// https://github.com/juny58/ion-custom-scrollbar/blob/master/projects/ion-custom-scrollbar/src/lib/scrollbar.directive.ts
// https://ionicframework.com/docs/api/content
::-webkit-scrollbar {
	width: 10px;
}
/* Track */
::-webkit-scrollbar-track {
	background: none;
}
/* Handle */
::-webkit-scrollbar-thumb {
	background: var(--accent);
	border-radius: 5px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #555;
}

/// Map 
.deck-tooltip { // default tooltip class override
	border-radius: 7px;
}
.map-control-panel {
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
}
.map-control-panel ion-card {
	margin: 20px;
	padding: 10px;
	border-radius: 10px;
}
.map-control-panel ion-item {
	color: var(--primaryText)
}
.map-control-panel ion-item ion-button {
	padding-top: 3px;
	padding-bottom: 3px;
}
.map-control-panel ion-thumbnail  {
	--border-radius: 5px;
}
.map-control-panel .ion-label-title {
	font-weight:500;
}
.map-control-panel .ion-label-body {
	font-weight: lighter;
}

.scanCheckBox {
	float: right;
	font-size: small;

	ion-checkbox {
		margin-left: 4px;
		margin-bottom: -4px;
		--border-color: var(--borderColor);
		--border-color-checked: var(--borderColor);
		--checkmark-color: var(--borderColor);
	}
}

.accentText {
	color: var(--accentText);
}

a {
	color: var(--accentDarkText);
}

.layerLabel {
	text-transform:capitalize;
	color: var(--primaryText)
}

.mini-profile {
	border: 2px solid var(--borderColor);
	display: inline-block;
	color: var(--accentText) !important;
	margin: 10px;
	padding: 8 30px;
	height: 30px;
	font-size: 16px;
	line-height: 25px;
	border-radius: 25px;
}

.mini-profile img {
	float: left;
	margin-left: 10px;
	height: 25px;
	background-color: white;
	border-radius: 50%;
	padding-top: 1px;
	padding-right: 2px;
}

.searchbar-input {
	background: white !important;
	font-size: 1.25em !important;
	border: 1px solid transparent;
	border-radius: 10px !important;
	height: 30px !important;
}

.searchbar-search-icon.sc-ion-searchbar-md {
	top: 6px;
}


.sc-ion-searchbar-ios-h {
	padding-bottom: 0 !important;
}

.badgeAsSelection {
	--background:var(--accent) !important;
	color: var(--secondaryBaseText);
	cursor:pointer;
}

.badgeAsOptions {
	--background:none !important;
	color: var(--accentText);
	border: 2px solid var(--borderColor);
	cursor:pointer;
}

.badgeAsOptions {
	--background:none !important;
	color: var(--accentText);
	border: 2px solid var(--borderColor);
	cursor:pointer;
}

.clearOptions {
	--background:none !important;
	color: red !important;
	border: 1px solid red !important;
	cursor:pointer;
}

// Gauges
.ng-star-inserted{
	color:var(--primaryText) !important;
}


// apex charts
.apexcharts-xaxis-label {
	fill: var(--primaryText) !important;
}
.apexcharts-xaxis text {
	color: var(--primaryText) !important;
	fill: var(--primaryText) !important;	
}
.apexcharts-yaxis-label {
	fill: var(--primaryText) !important;
	color: var(--primaryText) !important;
}
.apexcharts-yaxis text {
	color: var(--primaryText) !important;
	fill: var(--primaryText) !important;	
}

.apexcharts-title-text {
	color: var(--primaryText) !important;
	fill: var(--primaryText) !important;
}
.apexcharts-tooltip {
	background: #f3f3f3 !important;
	color: black !important;
	fill: black !important;
}
.apexcharts-tooltip span {
	color: black !important;
	fill: black !important;
}
.apexcharts-tooltip-text {
	color: black !important;
	fill: black !important;
}
.apexcharts-tooltip-text-y-label{
	color: black !important;
	fill: black !important;
}
.apexcharts-tooltip-y-group span {
	color: black !important;
	fill: black !important;
}

.area-custom-gradient {
	fill: linear-gradient(to bottom, #FF5733, #FFCC00);
}

ion-item-divider {
	border-color: var(--secondary);
	ion-label {
		--border-color: var(--secondary) !important; /* Replace with your desired color */
		border-color: var(--secondary);
	}
}

.btn-prev-next {
	border: 1px solid lightgray;
	background-color: transparent;
	color: var(--primaryText);
	width: 30px;
	font-size: large;
}

ion-accordion-group{
	ion-accordion {
		.ion-accordion-toggle-icon {
			color: var(--primaryText);
		}
	}
}

.device-status-box {
	border: 2px solid var(--secondary); /* Set the border color to your desired color */
	box-shadow: none; /* Set the box shadow color */
}

.counts {
	color: var(--primaryText);
	text-align: center;
}



gridster-item {
	background-color: var(--ion-background-color) !important; 
}

gridster {
	background-color: var(--ion-background-color) !important; 
}

ion-fab {
	ion-fab-button {
		--background: var(--accent);
		color: white;
	}
}

#chart path.apexcharts-area {
	fill: #ff5733; /* Set the fill color to your desired color */
}
  
/* Style the area path element when hovering (optional) */
#chart path.apexcharts-area:hover {
	fill: #ff33cc; /* Set the hover fill color to your desired color */
}


.card-indent{
	margin-left: 100px !important;
}

.card-indent:before {
	content: '';
	position: absolute;
	top: 50%;
	left: 111px; /* Adjust this value to position the arrow */
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 5px 7px 0 7px;
	border-color: #000 transparent transparent transparent; /* Change color if needed */
	transform: translateY(-50%) rotate(45deg);
}

.valid-alert {
	--background: green; /* Set the background color to green */
	/* You can add more custom styles here */
}
.invlid-alert {
	--background: red; /* Set the background color to red */
	/* You can add more custom styles here */
}


@import "./material-customize.scss";
