
// NOTE :: In your theme you can set now the main colros of your app for light/dark modes

/* active tab */
.mat-tabs .mat-ink-bar {
	background-color: var(--accent) !important;
}

.mat-table {
	overflow-x: scroll;
}

.mat-cell,
.mat-header-cell {
	word-wrap: initial;
	display: table-cell;
	padding: 0px 10px;
	line-break: unset;
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	vertical-align: middle;
}

.mat-row,
.mat-header-row {
	display: table-row;
}

.mat-tab-label {
	padding: 5px;
	background-color: transparent;
	color: var(--accent);
	font-weight: 700;
}

/* Styles for the active tab label */
.mat-tab-label.mat-tab-label-active {
	padding: 5px;
	background-color: transparent;
	color: var(--accent);
	font-weight: 700;
}

/* Styles for the ink bar */
.mat-ink-bar {
	background-color: var(--accent);
}

mat-label {
	color: var(--primaryText);
}
mat-form-field{
	color: var(--primaryText);
	input {
		color: var(--primaryText);
	}
}

.mat-icon{
	color: var(--primaryText);
}