@import '~@angular/material/theming';
@include mat-core();

.theme-app20{
	.dark {
		$dark-primary: mat-palette($mat-red, A200, A100, A400);
		$dark-accent:  mat-palette($mat-red, A200, A100, A400);
		$dark-warn:    mat-palette($mat-deep-orange);
		$dark-theme:   mat-dark-theme($dark-primary, $dark-accent, $dark-warn);
		@include angular-material-theme($dark-theme);

		--primary : #2D3035;
		--secondary: #C7C7CC;
		--secondaryBase: #fff;
		--accent : #FF422A;
		--secondaryDark: #383D43;
		--accentLight :rgba(182, 119, 119, 0.3);
		--accentBackground :rgba(209, 138, 138, 0.1);
		--accentDark: #2E2820;
		--borderColor: #9b1200;
		--accentHover: rgba(180, 61, 61, 0.5);
		--iconColor: #9b1200;

		--primaryText: #fff;
		--secondaryText: #8E8E93; 
		--accentText : #FF422A;
		--secondaryBaseText: #EFEFF4;
		--accentLightText: #2D3035;
		--accentDarkText: #000000;

		--ion-background-color: var(--primary);
	}

	.light {
		$light-primary: mat-palette($mat-red, 200, 500, 300);
		$light-accent: mat-palette($mat-red, 200);
		$light-warn: mat-palette($mat-deep-orange, 200);
		$light-theme: mat-light-theme($light-primary, $light-accent, $light-warn);
		@include angular-material-theme($light-theme);

		--primary : rgb(253, 253, 253);
		--secondary: #C7C7CC;
		--secondaryBase: #fff;
		--accent : #ac1501;
		--secondaryDark: rgb(42, 53, 68);
		--accentLight : rgba(255, 255, 255, 0.15);
		--accentBackground :rgba(209, 138, 138, 0.1);
		--accentDark: rgb(106, 43, 39);
		--borderColor: rgba(233,236,239, 0.8);
		--accentHover: rgba(255,70,64, 0.1);
		--iconColor: #5aa1bf;

		--primaryText: #73777c;
		--secondaryText: #8E8E93;
		--accentText : #ac1501;
		--secondaryBaseText: #EFEFF4;
		--accentLightText: #2D3035;
		--accentDarkText: #00000;

		--ion-background-color: var(--primary);
	}

	@import "./common.scss";
}